.main{
    padding:10px 10%;
    margin:0px;


    div{
        margin-top: 20px;
    }

    ul{
       margin-left: 30px; 
    }
}

h2{
    margin:10px 0px;
}

h3{
    margin-top:30px;
}
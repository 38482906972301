@import url('https://fonts.googleapis.com/css2?family=Gantari:wght@600&family=Inter:wght@400;600&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gantari', sans-serif;
  // border:1px solid red;
}

body{
    background-color: #fff;
}
    *{
        font-family: Lato!important;
    }
    .login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #FFF;
  background-repeat: no-repeat;
  position: fixed;
  box-sizing: border-box;
//   background-size: cover;
//   background-attachment: fixed;
        .first{

            width:40vw;
            .img{
                
                width:38vw;
                height:92vh;
                margin-top: 10vh;
                // object-fit: cover;
            }

        }

        .second{
            box-sizing: border-box;
            width:60vw;
            margin:0 10%;

            .login_card{
            width: 40%;
            .brand {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        } 


        .login_form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        }

        .third{
            display: none;
        }

        
}


    .text_shadow {
        -webkit-text-stroke: 1px black;
  text-shadow: 0px 8px 9px rgba(0,0,0,0.3)!important;
}

.welcome_sub_heading{
    color: #585858;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 162%; /* 22.68px */
text-transform: capitalize;
}

.border_radius{
    border-radius: 14px!important;
}

label{
    font-size: 14px;
    text-transform: uppercase;
}



@media only screen and (max-width: 600px) {
    .login {
    //   background-color: lightblue;
      width: 100%;
      height: auto;
      flex-direction: column;
  position: relative;

    //   height: auto;
    //   justify-content: flex-end;
      align-items: center;
      .first{
       display: none;
      }

      .second{
        width: 80%;
      }

      .third{
        display: block;
        margin-top: 20px;
        padding: 10px;
        color: #585858;
       }
    }
  }